export default {
  'status-main': {
    en: 'Status Management',
    de: 'Status Management',
  },

  'status-sub-01': {
    en: 'Status Overview',
    de: 'Status auf einen Blick',
  },
  'status-01': {
    en: 'Get a clear overview of the online or break status of all users.',
    de: 'Erhalte einen einfachen Überblick über den Online- oder Pausenstatus aller Nutzer.',
  },

  'status-sub-02': {
    en: 'Skill-Sharing ',
    de: 'Skill-Sharing ',
  },
  'status-02': {
    en: 'Search users by their skills and always find the right contact person.',
    de: 'Durchsuche Nutzer nach ihren Fähigkeiten und finde stets den passenden Ansprechpartner.',
  },

  'status-sub-03': {
    en: 'Uncomplicated Accessibility',
    de: 'Unkomplizierte Erreichbarkeit',
  },
  'status-03': {
    en: "See all users' contact availability and details in one place.",
    de: 'Erreichbarkeiten aller Nutzer und ihre Kontaktdaten auf einen Blick.',
  },
}
