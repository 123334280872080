export default {
  'action-btn-01': {
    en: 'Start Free Trial',
    de: 'Jetzt kostenlos testen',
  },
  'action-btn-02': {
    en: 'Book a Demo',
    de: 'Demo buchen',
  },

  'action-h1': {
    en: 'More productivity, less effort – Time tracking made easy!',
    de: 'Mehr Produktivität, weniger Aufwand – Zeiterfassung leicht gemacht!',
  },
  'action-h2': {
    en: 'Track work and break times with just one click. Access detailed statistics and export data quickly for maximum efficiency.',
    de: 'Arbeits- und Pausenzeiten mit nur einem Klick erfassen. Detaillierte Statistiken und einfache Exporte für maximale Effizienz.',
  },

  'top-link-01': {
    en: 'Learn more',
    de: 'Mehr erfahren',
  },

  'dashboard-img': {
    en: 'Dashboard with a graph for time tracking, a graph for absence tracking, and a list showing the status of users.',
    de: 'Dashboard mit einem Diagramm zur Zeiterfassung, einem Diagramm zur Abwesenheitserfassung und einer Liste mit dem Status der Nutzer.',
  },
}
