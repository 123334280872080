<template>
  <div class="navbar-container box-shadow row">
    <router-link
      :to="`/${$store.state.language}`"
      class="logo"
      @click="showMenu = false"
    >
      <i class="fa-solid fa-circle-nodes"></i>
      <span>Sharedex</span>
    </router-link>

    <div @click="showMenu = !showMenu" class="menu-toggle-btn row">
      <i v-if="!showMenu" class="fa-solid fa-bars"></i>
      <i v-else class="fa-solid fa-x"></i>
    </div>

    <Desktop @updateLanguage="updateLanguage($event)" />
  </div>

  <Mobile
    :showMenu="showMenu"
    @closeMenu="showMenu = false"
    @updateLanguage="updateLanguage($event)"
  />
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import Desktop from './navbar/Desktop.vue'
import Mobile from './navbar/Mobile.vue'

export default {
  name: 'MainNavbar',
  components: { Desktop, Mobile },
  setup() {
    const store = useStore()
    const showMenu = ref(false)
    const route = useRoute()
    const router = useRouter()

    const updateLanguage = (language) => {
      let url = route.fullPath
      let updatedUrl = url.slice(0, 1) + language + url.slice(3)
      router.push(updatedUrl)

      showMenu.value = false
    }

    return {
      showMenu,
      updateLanguage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.navbar-container {
  position: fixed;
  top: 10px;
  background-color: white;
  width: calc(95% - 30px);
  padding: 0 15px;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  margin-left: calc(2.5%);
  border-radius: 25px;
  box-shadow: 0 0.15rem 0.5rem rgba(3, 8, 20, 0.1),
    0 0.075rem 0.175rem rgba(2, 8, 20, 0.1);

  a {
    color: var(--dark-10) !important;
  }

  .logo {
    font-size: 2.5rem;
    color: var(--dark-10);

    i {
      margin-right: 10px;
    }
  }

  .menu-toggle-btn {
    display: none !important;
    font-size: 1.5rem;
    background-color: var(--dark-10);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    @media (orientation: portrait) or (max-width: 600px) {
      display: flex !important;
    }

    i {
      color: white;
      transition: all 0.2s linear;

      &.fa-bars,
      &.fa-x {
        animation: fade 0.5s linear;
      }

      @keyframes fade {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
</style>
