<template>
  <div
    class="image-container"
    v-motion
    :initial="{ opacity: 0, y: 100 }"
    :visible="{ opacity: 1, y: 0 }"
    :delay="200"
  >
    <div v-if="images.length > 1" @click="changeImage('back')" class="back row">
      <i class="fa-solid fa-angle-left"></i>
    </div>

    <div class="image row" v-for="(image, i) in images" :key="image">
      <img
        :src="require(`@/assets/img/${image.src}`)"
        :alt="`${image.alt}`"
        class="box-shadow"
        :class="{ hidden: currentImage !== i }"
      />
    </div>

    <div v-if="images.length > 1" @click="changeImage('next')" class="next row">
      <i class="fa-solid fa-angle-right"></i>
    </div>

    <div v-if="images.length > 1" class="current-image row">
      <div
        v-for="i in images.length"
        :key="i"
        class="dot box-shadow"
        :class="{ active: currentImage === i - 1 }"
        @click="changeImage('jump', i - 1)"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'ImageSlider',
  props: ['images'],
  setup(props) {
    const currentImage = ref(0)
    const updateImage = ref('')

    function changeImage(direction = 'next', position = 0) {
      if (direction === 'jump') {
        currentImage.value = position
        return
      }

      if (
        direction === 'next' &&
        currentImage.value < props.images.length - 1
      ) {
        currentImage.value++
        updateImage.value = 'next'
      } else if (
        direction === 'next' &&
        currentImage.value >= props.images.length - 1
      ) {
        currentImage.value = 0
        updateImage.value = 'next'
      } else if (direction === 'back' && currentImage.value > 0) {
        currentImage.value--
        updateImage.value = 'back'
      } else if (direction === 'back' && currentImage.value <= 0) {
        currentImage.value = props.images.length - 1
        updateImage.value = 'back'
      }
    }

    return {
      currentImage,
      changeImage,
      updateImage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.image-container {
  width: calc(100% - 20px);
  margin: 40px 10px;
  max-width: 1200px;
  display: grid;
  grid-template-rows: 90% 10%;
  grid-template-columns: 45px calc(100% - 100px) 45px;
  column-gap: 5px;
  grid-template-areas:
    'back image next'
    'current current current';

  @media only screen and (max-width: 450px) {
    column-gap: 0;
    row-gap: 5px;
    grid-template-columns: 10% 80% 10%;
    grid-template-areas:
      'image image image'
      'back current next';
  }

  .back,
  .next {
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 7px;

    @media only screen and (max-width: 450px) {
      height: 50px;
    }

    i {
      font-size: 2rem;
      color: var(--gray);
    }

    &:hover {
      background-color: lightgray;
    }
  }

  .back {
    grid-area: back;
  }

  .next {
    grid-area: next;
  }

  .image {
    grid-area: image;
    align-items: center;
    justify-content: center;
    border-radius: 7px;

    @media only screen and (max-width: 450px) {
      margin-bottom: 10px;
    }

    img {
      width: 100%;
      border-radius: 7px;
      animation: fade-enter 0.5s ease-in-out;
    }
  }

  @keyframes fade-enter {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .current-image {
    grid-area: current;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    @media only screen and (max-width: 450px) {
      margin-top: 0px;
      height: 50px;
    }

    .dot {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: lightgray;
      margin: 0 2px;
      cursor: pointer;

      &.active {
        background-color: var(--gray);
      }
    }
  }
}
</style>
