<template>
  <MainNavbar />
  <router-view />
  <Offer v-if="showOffer" @close="showOffer = false" />
  <CookieBanner :showOffer="showOffer" />
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

import Offer from '@modules/pricing/components/Offer.vue'
import MainNavbar from '@components/navigation/Navbar.vue'
import CookieBanner from '@components/cookieBanner/CookieBanner.vue'

export default {
  name: 'App',
  components: { Offer, MainNavbar, CookieBanner },
  setup() {
    const store = useStore()
    const showOffer = ref(true)

    onMounted(async () => {
      await store.dispatch('ping')
      await store.dispatch('pingDemo')
    })

    return {
      showOffer,
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Rubik+Mono+One&display=swap');
@import '@/assets/variables.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

#app {
  width: 100%;
  margin: 0;
  color: var(--dark-10);
  overflow-x: hidden;

  .box-shadow {
    box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem,
      rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  }

  .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1240px;

    @media only screen and (max-width: 450px) {
      width: 95%;
    }
  }

  .row {
    display: flex;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .rotate {
    transform: rotate(180deg);
    transition: all 0.2s linear;
  }

  .hidden {
    display: none;
  }

  a {
    text-decoration: none;
  }

  i {
    transition: all 0.2 linear;
  }

  .feature-header {
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
    margin: 0 10px;

    h3 {
      font-size: 2.25rem;
      margin-bottom: 0;
      text-align: center;
      line-height: 50px;
    }

    .line {
      width: 100px;
      height: 10px;
      border-radius: 25px;
      background-color: var(--medium);
      margin: 10px 0 10px 0;
    }

    @media only screen and (max-width: 450px) {
      h3 {
        font-size: 1.75rem;
      }
    }
  }
}
</style>
