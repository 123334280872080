<template>
  <div
    class="reason box-shadow row"
    v-motion
    :initial="{ opacity: 0, x: -100 }"
    :visible="{ opacity: 1, x: 0 }"
    :delay="200"
  >
    <div class="icon-container row">
      <div class="icon row">
        <i :class="icon"></i>
      </div>
    </div>

    <div class="content">
      <h2>{{ t(headline) }}</h2>
      <p>{{ t(text) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['icon', 'headline', 'text'],
  setup() {},
}
</script>

<style lang="scss" scoped>
.reason {
  width: calc(100% - 60px);
  padding: 30px;
  max-width: 700px;
  margin: 0 auto;
  border-radius: 15px;
  margin-bottom: 20px;

  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }

  .icon-container {
    width: 100px;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 450px) {
      width: 100%;
    }

    .icon {
      background-color: var(--purple-5);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      i {
        color: white;
        font-size: 3rem;
      }
    }
  }

  .content {
    width: calc(100% - 140px);
    margin-left: 40px;

    @media only screen and (max-width: 450px) {
      width: 100%;
      margin-left: 0;

      h2 {
        text-align: center;
      }
    }
  }
}
</style>
