<template>
  <div
    v-if="showCookie"
    class="cookie-banner row"
    :style="{ bottom: showOffer ? '60px' : '10px', opacity: 1 - scrollY }"
  >
    <p v-if="$store.state.language === 'en'">
      Sharedex uses cookies. By continuing to browse, you agree to our use of
      cookies.

      <router-link :to="`/${$store.state.language}/privacy`">
        Learn more
      </router-link>
    </p>

    <p v-if="$store.state.language === 'de'">
      Sharedex verwendet Cookies. Durch das weitere nutzen unserer Website
      stimmst du der Verwendung von Cookies zu.

      <router-link :to="`/${$store.state.language}/privacy`">
        Mehr erfahren
      </router-link>
    </p>

    <button @click="acceptCookies">Ok</button>
  </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue'

export default {
  name: 'BaseCookieBanner',
  props: ['showOffer'],
  setup() {
    const showCookie = ref(true)
    const scrollY = ref(0)

    function acceptCookies() {
      showCookie.value = false
      localStorage.setItem('cookie_consent', true)
      localStorage.setItem('last_access', new Date().getTime())
    }

    onBeforeMount(() => {
      const cookieConsent = localStorage.getItem('cookie_consent') || false

      if (cookieConsent) showCookie.value = false
      else {
        window.addEventListener('scroll', function (event) {
          scrollY.value = window.scrollY / 300

          if (scrollY.value > 1) acceptCookies()
        })
      }
    })

    return {
      showCookie,
      scrollY,
      acceptCookies,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';

.cookie-banner {
  position: fixed;
  right: 10px;
  background-color: var(--dark-10);
  padding: 20px;
  border-radius: 15px;
  z-index: 10;

  p {
    max-width: 350px;
    margin: 0 20px 0 0;
    color: white !important;
    font-size: 0.9rem !important;

    a {
      color: white;
      text-decoration: underline !important;
    }
  }

  button {
    height: 40px;
    padding: 0 25px;
    border-radius: 25px;
    background-color: white;
    color: var(--dark-10);
    font-size: 1rem;
    margin: auto 0 0 0;
    cursor: pointer;
    border: none;
  }
}
</style>
