import { createStore } from 'vuex'

let os = navigator.userAgent
let url = 'https://sharedex-a12fc96d12a8.herokuapp.com'
let mode = 'live'
let language = 'en'
let supportedLanguages = ['en', 'de']
let userLanguage =
  navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0]

if (os.includes('Android') || os.includes('iOS')) os = 'mobile'
else os = 'web'

if (window.location.href.includes('http://localhost:8080')) {
  url = 'http://localhost:3000'
  mode = 'test'
}

if (supportedLanguages.includes(userLanguage)) {
  language = userLanguage
}

export default createStore({
  state() {
    return {
      url: url,
      mode: mode,
      os: os,
      language: language,
    }
  },

  mutations: {
    setLanguage(state, language) {
      if (supportedLanguages.includes(language)) state.language = language
      else state.language = 'en'
    },
  },

  actions: {
    /*
      ROUTE /ping
    */
    async ping() {
      if (mode === 'test') return

      const response = await fetch(
        'https://sharedex-a12fc96d12a8.herokuapp.com/ping',
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      )
    },

    async pingDemo() {
      if (mode === 'test') return

      const response = await fetch(
        'https://sharedex-demo-ed1a17b0847e.herokuapp.com/ping',
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      )
    },
  },
})
