<template>
  <div class="desktop-menu links row">
    <router-link :to="`/${$store.state.language}/pricing`" class="link row">
      <div class="icon row">
        <i class="fa-regular fa-credit-card"></i>
      </div>
      <span>{{ t('nav-01') }}</span>
    </router-link>

    <a
      href="https://app.sharedex.co/auth/login"
      target="_blank"
      rel="noopener noreferrer"
      class="link row"
    >
      <div class="icon row">
        <i class="fa-solid fa-user"></i>
      </div>
      <span>{{ t('nav-02') }}</span>
    </a>

    <a
      @click="showMenu = false"
      :href="`https://app.sharedex.co/auth/register?language=${$store.state.language}`"
      target="_blank"
      rel="noopener noreferrer"
      class="link row"
    >
      <div class="icon row">
        <i class="fa-solid fa-user-plus"></i>
      </div>
      <span>{{ t('nav-03') }}</span>
    </a>

    <div class="languages-container column">
      <div
        @click="showLanguages = !showLanguages"
        class="link language-toggle row"
      >
        <div class="icon row">
          <i
            class="fa-solid fa-caret-down"
            :class="{ rotate: showLanguages }"
          ></i>
        </div>
        <i class="fa-solid fa-language"></i>
      </div>

      <div v-if="showLanguages" class="languages column box-shadow">
        <router-link @click="updateLanguage('de')" to="" class="link row">
          {{ t('nav-04') }}
        </router-link>

        <router-link @click="updateLanguage('en')" to="" class="link row">
          {{ t('nav-05') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'

export default {
  name: 'Desktop',
  emits: ['updateLanguage'],
  setup(_, { emit }) {
    const showLanguages = ref(false)

    const updateLanguage = (language) => {
      emit('updateLanguage', language)
      showLanguages.value = false
    }

    watchEffect(() => {
      if (showLanguages.value) {
        setTimeout(() => {
          showLanguages.value = false
        }, 15000)
      }
    })

    return {
      showLanguages,
      updateLanguage,
    }
  },
}
</script>

<style lang="scss" scoped>
.desktop-menu {
  @media (orientation: portrait) or (max-width: 600px) {
    display: none !important;
  }

  .languages-container {
    align-items: center;
    justify-content: center;
    position: relative;

    .language-toggle {
      background-color: var(--purple-0);
      padding: 7px 10px;
      border-radius: 15px;
    }

    .languages {
      background-color: white;
      position: absolute;
      top: 40px;
      left: 3px;
      align-items: center;
      justify-content: center;

      .link {
        width: calc(100% - 30px);
        margin-left: 0;
        padding: 5px 15px;

        &:hover {
          background-color: var(--purple-0);
        }
      }
    }
  }

  .link {
    color: var(--dark-10) !important;
    font-size: 1.25rem;
    align-items: center;
    justify-content: start;
    font-weight: bold;
    letter-spacing: -1px;
    margin-left: 20px;
    cursor: pointer;

    .icon {
      i {
        color: var(--purple-5);
        margin-right: 15px;
      }
    }
  }

  .link:hover {
    span {
      text-decoration: underline !important;
    }
  }
}
</style>
