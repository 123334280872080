import { createApp } from 'vue'
import { MotionPlugin } from '@vueuse/motion'
import router from './router'
import store from './store'
import './registerServiceWorker'

import App from './App.vue'
import translation from './translation'

import ImageSlider from '@components/imageSlider/ImageSlider.vue'
import RowCard from '@components/cards/RowCard.vue'
import ColumnCard from '@components/cards/ColumnCard.vue'

const app = createApp(App)

app.use(MotionPlugin)
app.use(store)
app.use(router)
app.use(translation)

app.component('ImageSlider', ImageSlider)
app.component('RowCard', RowCard)
app.component('ColumnCard', ColumnCard)

app.mount('#app')
