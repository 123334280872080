<template>
  <div class="offer-banner row">
    <div class="text row">
      <span class="desktop">{{ t('offer-01') }}</span>
      <span class="mobile">{{ t('offer-01-mobile') }}</span>

      <router-link :to="`/${$store.state.language}/pricing`" class="link row">
        {{ t('top-link-01') }}
      </router-link>
    </div>

    <div class="close row" @click="$emit('close')">
      <i class="fa-solid fa-xmark"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Offer',
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.offer-banner {
  position: fixed;
  bottom: 0;
  width: calc(100% - 40px);
  padding: 0 20px;
  height: 50px;
  background-color: var(--purple-5);
  color: white;
  align-items: center;
  justify-content: space-between;
  z-index: 10;

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 25px);
    justify-content: center;

    .mobile {
      display: none;
    }

    @media only screen and (max-width: 800px) {
      .desktop {
        display: none;
      }

      .mobile {
        display: flex;
      }
    }

    a {
      color: white;
      text-decoration: underline !important;
      margin-left: 10px;
    }
  }

  .close {
    width: 25px;
    font-size: 1.25rem;
    cursor: pointer;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
