<template>
  <div
    class="reason column box-shadow"
    v-motion
    :initial="{ opacity: 0, y: 100 }"
    :visible="{ opacity: 1, y: 0 }"
    :delay="200"
  >
    <div class="icon row">
      <i :class="icon"></i>
    </div>

    <h2>{{ t(headline) }}</h2>

    <p>{{ t(text) }}</p>
  </div>
</template>

<script>
export default {
  props: ['icon', 'headline', 'text'],
  setup() {},
}
</script>

<style lang="scss" scoped>
.reason {
  width: calc(100% / 2);
  align-items: center;
  border-radius: 15px;
  margin: 0 10px;
  padding: 30px;

  @media only screen and (max-width: 450px) {
    padding: 30px 15px;
    width: calc(100% - 30px);
    margin: 0 0 20px 0;
  }

  .icon {
    background-color: var(--purple-5);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px auto;

    i {
      color: white;
      font-size: 2rem;
    }
  }

  h2 {
    margin: 0;
    text-align: center;
    line-height: 30px;
    height: 60px;
  }
}
</style>
