<template>
  <div class="mobile-menu column" :class="{ show: showMenu }">
    <a
      @click="$emit('closeMenu')"
      :href="`https://app.sharedex.co/auth/login?language=${$store.state.language}`"
      class="link row"
    >
      <div class="icon row">
        <i class="fa-solid fa-user"></i>
      </div>
      <span>{{ t('nav-02') }}</span>
    </a>

    <a
      @click="$emit('closeMenu')"
      :href="`https://app.sharedex.co/auth/register?language=${$store.state.language}`"
      class="link row"
    >
      <div class="icon row">
        <i class="fa-solid fa-user-plus"></i>
      </div>
      <span>{{ t('nav-03') }}</span>
    </a>

    <div class="line"></div>

    <router-link
      :to="`/${$store.state.language}/pricing`"
      class="link row"
      @click="$emit('closeMenu')"
    >
      <div class="icon row">
        <i class="fa-regular fa-credit-card"></i>
      </div>
      <span>{{ t('nav-01') }}</span>
    </router-link>

    <router-link
      :to="`/${$store.state.language}/#time`"
      class="link row"
      @click="$emit('closeMenu')"
    >
      <div class="icon row">
        <i class="fa-solid fa-clock"></i>
      </div>
      <span>{{ t('nav-06') }}</span>
    </router-link>

    <router-link
      :to="`/${$store.state.language}/#status`"
      class="link row"
      @click="$emit('closeMenu')"
    >
      <div class="icon row">
        <i class="fa-solid fa-earth-europe"></i>
      </div>
      <span>{{ t('nav-07') }}</span>
    </router-link>

    <div class="line"></div>

    <div class="languages-container column">
      <router-link
        @click="showLanguages = !showLanguages"
        to=""
        class="link language-toggle row"
      >
        <div class="icon row">
          <i
            class="fa-solid fa-caret-down"
            :class="{ rotate: showLanguages }"
          ></i>
        </div>
        <span>{{ t('nav-08') }}</span>
      </router-link>

      <transition name="translate-x">
        <div v-if="showLanguages" class="languages row">
          <div
            @click="$emit('updateLanguage', 'de')"
            class="link box-shadow row"
          >
            {{ t('nav-04') }}
          </div>

          <div
            @click="$emit('updateLanguage', 'en')"
            class="link box-shadow row"
          >
            {{ t('nav-05') }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Mobile',
  emits: ['updateLanguage', 'closeMenu'],
  props: ['showMenu'],
  setup() {
    const showLanguages = ref(false)

    return {
      showLanguages,
    }
  },
}
</script>

<style lang="scss" scoped>
.mobile-menu {
  height: 100%;
  position: fixed;
  top: 0;
  left: -600px;
  width: calc(100% - 60px);
  max-width: 500px;
  height: 100%;
  z-index: 5;
  padding: 100px 30px 20px 30px;
  background-color: white;
  transition: left 0.2s linear;

  &.show {
    left: 0;
    transition: left 0.2s linear;
  }

  .link {
    color: var(--dark-10) !important;
    align-items: center;
    justify-content: start;
    font-weight: bold;
    letter-spacing: -1px;
    margin-left: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: 0;
    font-size: 1.5rem;

    .icon {
      width: 50px;
      margin-right: 0;
      color: var(--purple-5);

      .fa-caret-down {
        margin-left: 3px;
      }

      .fa-credit-card {
        margin-left: -3px;
      }
    }
  }

  .line {
    width: 100%;
    border: 1px solid lightgray;
    margin: 0 0 10px 0;
  }

  .languages {
    justify-content: space-between;

    .link {
      width: 49%;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
      border-radius: 15px;
    }
  }
}
</style>
