import store from '../store/index.js'
import navigation from '@components/navigation/translation.js'
import banner from '@modules/home/components/banner/translation.js'
import separator from '@components/separator/translation.js'
import time from '@modules/home/components/time/translation.js'
import absence from '@modules/home/components/absence/translation.js'
import status from '@modules/home/components/status/translation.js'
import pricing from '@modules/pricing/translation.js'

const translation = {
  ...navigation,
  ...banner,
  ...separator,
  ...time,
  ...absence,
  ...status,
  ...pricing,
}

export const translate = (key, replace = '') => {
  if (!translation[key]) return ''

  const language = store._state.data.language || 'en'
  let str = translation[key][language]

  if (str.includes('%r%')) {
    str = str.replace('%r%', replace)
  }

  return str
}

export default {
  install: (app) => {
    app.config.globalProperties.t = translate
  },
}
